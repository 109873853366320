import React from 'react'
import Slider from 'react-slick'
import { courses } from '../../utilities/constants'
import { useAddonContext } from '../../context/AddonFeatureContext'

const settings = {
    // dots: true,
    slidesToShow: 3,
    rows: 1,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 5000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                infinite: true,
                arrows: false,
                swipeToSlide: false,
            }
        },
    ]
}

const CourseCategories = () => {

    const { handleSkillSelection, setShowCategoryModal } = useAddonContext()

    const handleClick = (index) => {
        setShowCategoryModal(true)
        handleSkillSelection(index)
    }

    return (
        <div className='text-white mt-5 md:mt-7'>
            <h3 className='text-xl md:text-[32px] font-semibold mb-2'>1240+ Courses In 13+ Categories</h3>
            <div className='w-full text-center'>
                <Slider {...settings}>
                    {courses.map((item, index) => (
                        <div key={index} className='w-full cursor-pointer' onClick={() => handleClick(index)}>
                            <div className='w-[95%] bg-secondary_background min-h-[128px] flex flex-col items-center py-3 px-2 rounded-2xl'>
                                <div className='w-[36px] h-[36px] mb-3'>
                                    <img src={item.image} alt="" className='w-full h-full object-cover'/>
                                </div>
                                <div className='flex-1'>
                                    <p className='w-full leading-4 text-xs line-clamp-2'>{item?.name}</p>
                                </div>
                                <div className='line-clamp-1 w-full underline text-secondary-text text-xs flex'>
                                    <p className='text-xs flex-1'>{item?.mskill_count} Courses</p> 
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            
        </div>
    )
}

export default CourseCategories