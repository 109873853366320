import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { useSelector } from 'react-redux'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { nineNintyNine } from '.'

const SeoAdditionalFeatures = ({ skill_name }) => {

    const priceChart = useSelector(selectPriceChart)

    const skillIsBusinessManagement = nineNintyNine.includes(skill_name?.toLowerCase())

    const { threeCertificates, proMembershipSelected, personalisedSelected, handleToggle1, handleToggle2, handleToggle3, skillIsNotSeo } = useAddonContext()
    return (
        <div className='text-[#7FC241] mt-4'>
            <p className='text-sm md:text-xl font-medium mb-2'>Add Popular Benefits (Optional)</p>
            <div className='mt-2'>
                {skillIsNotSeo && <div className={`bg-[#093159] flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer rounded-lg transform transition-transform ${threeCertificates ? 'border-2 border-[#7FC241]' : ''}`} onClick={handleToggle1}>
                    <div className='flex items-center'>
                        <div className={`w-5 md:w-8 h-5 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] ${threeCertificates ? 'bg-[#7FC241]' : 'bg-[#1F3F60]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                            <p className='w-[78%] text-left text-sm md:text-xl font-semibold'>{priceChart[2]?.product_description}</p>
                    </div>
                    <div className=''>
                        <p className='md:text-xl'>&#8377;{skillIsBusinessManagement ? priceChart[37].price : priceChart[2].price}</p>
                        <p className='line-through text-xs md:text-base text-danger'>&#8377;{skillIsBusinessManagement ? priceChart[37].original_price : priceChart[2].original_price}</p>
                    </div>
                </div>}
                {<div className={`bg-[#093159] flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer rounded-lg transform transition-transform ${personalisedSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => {
                    handleToggle3()
                }}>
                    <div className='flex items-center'>
                        <div className={`w-5 md:w-8 h-5 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] ${personalisedSelected ? 'bg-[#7FC241]' : 'bg-[#1F3F60]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                            <p className='w-[78%] text-left text-sm md:text-xl font-semibold'>Personalised {skill_name} Course Built For You Basis Your Quiz</p>
                    </div>
                    <div className=''>
                        <p className='md:text-xl'>&#8377;{priceChart[4]?.price}</p>
                        <p className='line-through text-xs md:text-base text-danger'>&#8377;{priceChart[4]?.original_price}</p>
                    </div>
                </div>}
                {<div className={`bg-[#093159] flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer rounded-lg transform transition-transform ${proMembershipSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => {
                    handleToggle2()
                }}>
                    <div className='flex items-center'>
                        <div className={`w-5 md:w-8 h-5 md:h-8 border rounded flex items-center justify-center border-[#7FC241] ${proMembershipSelected === true ? 'bg-[#7FC241]' : 'bg-[#1F3F60]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                        <p className='w-[78%] text-left text-sm md:text-xl font-semibold'>Unlimited Access to {skill_name} & 1240+ More Certified Courses</p>
                    </div>
                    <div className=''>
                        <p className='md:text-xl'>&#8377;{priceChart[3]?.price}</p>
                        <p className='line-through text-xs md:text-base text-danger'>&#8377;{priceChart[3]?.original_price}</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default SeoAdditionalFeatures