import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { companyList1 } from '../../utilities/constants'
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { selectRelatedJobs } from '../../redux/otherDetailsSlice'


const companyJobs = [
    {
        companeName: "Spotify",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(5).png?updatedAt=1709809846765",
    },
    {
        companeName: "Apple",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(6).png?updatedAt=1709809878740",
    },
    {
        companeName: "Figma",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(7).png?updatedAt=1709809915165",
    },
    {
        companeName: "TCS",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(8).png?updatedAt=1709809960378",
    },
    {
        companeName: "Adobe",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(10).png?updatedAt=1709810011200",
    },
    {
        companeName: "Slack",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(11).png?updatedAt=1709810053876",
    },
    {
        companeName: "Twitter",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(9).png?updatedAt=1709810010915",
    },
    {
        companeName: "Twitter",
        companyImage: "https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(9).png?updatedAt=1709810010915",
    },
]

const CertificateRecognition = ({ settings }) => {

    const { width } = useWindowSize()
    const relatedJobsFromApi = useSelector(selectRelatedJobs)

    const relatedJobSettings =  {
        dots: false,
        slidesToShow: 3,
        rows: 1,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 3000,
        easing: 'linear',
        cssEase: "linear",
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: width <= 360 ? 2.5 : 3,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    swipeToSlide: false,
                }
            },
        ]
    }

    const relatedJobs = useMemo(() => {
        let jobIndex = Math.floor(Math.random()*(companyJobs.length - relatedJobsFromApi?.length))
        const array = relatedJobsFromApi?.map((item, index) => {
            const packageUpperValue = Math.floor((Math.random() * (18 - 7)) + 7)
            const packageLowerValue = Math.floor(packageUpperValue * (0.6 + Math.random() * (0.75 - 0.6)))
            return {
                ...companyJobs[jobIndex + index],
                jobName: item,
                packageUpperValue,
                packageLowerValue
            }
        })

        return array
    }, [relatedJobsFromApi])
    
    // console.log(relatedJobs)
    return (
        <div className='bg-secondary_background rounded-xl'>
            <div className='pt-3'>
                <Slider {...settings}>
                    {companyList1.map((item, index) => (
                        <div key={index} className={`w-full flex items-center`}>
                            <img src={item} alt="" width={width >= 560 ? 150 : 100} height={width >= 560 ? 88.5 : 59} className=''/>
                        </div>
                    ))}
                </Slider>
            </div>
            {relatedJobs?.length > 0 && <div className='pb-4'>
                <div className='flex relative mb-3 mt-1'>
                    <div className={`absolute left-0 inset-y-0 my-auto ${width <= 360 ? 'w-[15%]' : 'w-1/5'} md:w-[30%] h-1 bg-[#172C3F]`}/>
                    <h4 className='w-full text-sm leading-4 font-semibold text-center'>Roles You Will Be Eligible For</h4>
                    <div className={`absolute right-0 inset-y-0 my-auto ${width <= 360 ? 'w-[15%]' : 'w-1/5'} md:w-[30%] h-1 bg-[#172C3F]`}/>
                </div>
                <Slider {...relatedJobSettings}>
                    {relatedJobs?.map((item, index) => (<div key={index} className='w-full'>
                        <div className='w-[95%] flex bg-[#213A51] rounded-xl py-2 px-3 gap-2 min-h-[80px] md:min-h-[60px]'>
                            <div className='w-5 h-full'>
                                <img src={item?.companyImage} width={20} height={20} className='w-full h-auto' alt="" />
                            </div>
                            <div className='text-xs flex flex-col font-semibold text-left flex-1'>
                                <p className='line-clamp-2'>{item.companeName}</p>
                                <p className='text-[10px] text-[#B0C1CD] leading-3 flex-1 line-clamp-2 md:line-clamp-1 mt-1.5'>{item.jobName}</p>
                                <p className='text-[10px] text-[#B0C1CD] leading-3 pt-2.5'>₹{item.packageLowerValue}-{item.packageUpperValue} Lpa</p>
                            </div>
                        </div>
                    </div>))}
                </Slider>
            </div>}
        </div>
    )
}

export default CertificateRecognition