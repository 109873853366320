import React from 'react'
import Slider from 'react-slick';
import { linkdInCertificateList } from '../../utilities/constants';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';

const settings = {
    dots: true,
    slidesToShow: 1,
    rows: 1,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 2000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    arrows: true,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                infinite: true,
                dots: true,
                arrows: false,
                swipeToSlide: false
            }
        }
    ]
};

const LinkdInUsers = () => {

    const [ showMore, setShowMore ] = useState(Array(linkdInCertificateList.length).fill(false))
    const { width } = useWindowSize()

    const handleShowMore = (index) => {
        const tempArray = [...showMore]
        tempArray[index] = !showMore[index]
        setShowMore(tempArray)
    }
    
    return (
        <div className='w-full'>
            <div className='block md:hidden w-full'>
            <Slider {...settings}>
                {linkdInCertificateList.map((item, index) => (<div className='' key={index}>
                    <div className={`bg-[#2D4F71] rounded-xl p-4 xs:min-h-[684px] w-[99%] ${showMore[index] ? '' : width <= 320 ? 'min-h-[520px]': width <= 375 ? 'min-h-[530px]' : 'min-h-[550px] '}`} key={index}>
                        <div className={`flex justify-between gap-2 ${index === 0 ? 'mt-1' : ''}`}>
                            <div className='flex gap-2'>
                                <img src={item?.profile_image} width={54} height={54} className='w-[54px] h-[54] rounded-full object-cover flex-shrink-0' alt="" />
                                <div className='flex flex-col'>
                                    <p className='text-sm font-bold'>{item.name}</p>
                                    <p className='text-xs font-medium text-white/50 line-clamp-1'>{item.designation}</p>
                                </div>
                            </div>
                            <div className='w-8 h-8 flex-shrink-0'>
                                <Link to={item.post_link}>
                                    <img src='https://ik.imagekit.io/238r9emirjj/LinkdinUsers/linkedin%201.png?updatedAt=1698817296779' className='w-full h-full object-cover' alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className={`${index === 0 ? 'mb-5 xs:mb-6' : (index === 6 && width <= 375) ? 'mb-0' : index === 6 ? 'mb-2.5 xs:mb-6' : index === 4 ? 'mb-4' : 'xs:mb-5'}`}>
                            <div className={`${showMore[index] ? '' : index === 0 ? 'line-clamp-3 xs:line-clamp-none' : index === 1 ? 'line-clamp-3 xs:line-clamp-3' : index === 5 ? 'line-clamp-[9] xs:line-clamp-[8]' : item.comments.length > 0  ? 'line-clamp-4' : 'line-clamp-[8]'} mt-2`}>
                                {item.description.split('\n').map((line, lineIndex) => (
                                    <div key={lineIndex} className='mt-2 text-xs' dangerouslySetInnerHTML={{ __html: line}}/>
                                ))}
                            </div>
                            <p onClick={() => handleShowMore(index)} className={`${index === 6 ? 'hidden' : ''} ${index === 0 || index === 4 ? 'xs:hidden' : ''} ${index === 0  ? 'mt-2 xs:mt-1' : index === 1 ? 'mt-2.5 xs:mt-2' : index === 5 ? 'mt-2 xs:mt-1.5' : index === 8 || index === 9 ? 'mt-1' :'mt-1 xs:mt-1.5'} text-[#40DA90] text-xs font-medium underline cursor-pointer`}>{showMore[index] ? 'Show Less' : 'Show More'}</p>
                        </div>
                        <div className={`${index === 6 && width <= 375 ? 'mt-2' : index === 6 ? 'mt-4' : 'mt-3'}`}>
                            <img src={item.certificate_image} className="rounded-xl" alt="" />
                        </div>
                        <div className='flex justify-between mt-2'>
                            <div className='flex items-center'>
                                {item?.icon_image}
                                <p className='text-xs font-medium'>{item.likes}</p>
                            </div>
                            {item.comments.length > 0 && <div className='text-xs'>
                                {item.comments.length} {item.comments.length > 1 ? 'comments' : 'comment'}
                            </div>}
                        </div>
                        <div className={`border-t border-[#3B6EA0] ${(index === 4 || index === 0 ) && (width > 393 && width < 480) ? 'mt-2' : index === 6 && width <= 375 ? 'mt-2' : 'mt-4'}`}/>
                        <div className={`w-full ${index === 6 && width <= 375 ? 'mt-1' : item.comments.length > 0 ? 'mt-2' : ''}`}>
                            {item.comments.map((comment) => (
                                <div key={comment.id} className='flex gap-2 pt-2 w-full'>
                                    <img src={comment.user_image} className='w-9 h-9 rounded-full' alt="" />
                                    <div className='flex flex-col rounded-b-lg rounded-tr-lg bg-[#1F3954] flex-1 p-2'>
                                        <div className='leading-[14px]'>
                                            <p className='text-[11px] font-medium'>{comment.user_name} <span className='text-white/50'>{comment.gender}</span></p>
                                            <p className='text-[9px] font-medium line-clamp-1 text-white/50'>{comment.designation}</p>
                                        </div>
                                        <div className={`text-[11px] ${index === 6 ? 'mt-1 xs:mt-2' : 'mt-2'}`} dangerouslySetInnerHTML={{ __html: comment.description }}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>))}
            </Slider>
            </div>
            <div className='hidden md:block'>
                <Slider {...settings}>
                    {linkdInCertificateList.map((item, index) => (<div className='' key={index}>
                        <div className={`bg-[#2D4F71] rounded-xl p-4 xs:min-h-[684px] md:min-h-[733px] w-[99%] ${showMore[index] ? '' : width <= 320 ? 'min-h-[520px]': width <= 375 ? 'min-h-[530px]' : 'min-h-[550px] '}`} key={index}>
                            <div className={`flex justify-between gap-2 ${index === 0 ? 'mt-1' : ''}`}>
                                <div className='flex gap-2'>
                                    <img src={item?.profile_image} width={54} height={54} className='w-[54px] h-[54] rounded-full object-cover flex-shrink-0' alt="" />
                                    <div className='flex flex-col'>
                                        <p className='text-sm font-bold'>{item.name}</p>
                                        <p className='text-xs font-medium text-white/50 line-clamp-1'>{item.designation}</p>
                                    </div>
                                </div>
                                <div className='w-8 h-8 flex-shrink-0'>
                                    <Link to={item.post_link}>
                                        <img src='https://ik.imagekit.io/238r9emirjj/LinkdinUsers/linkedin%201.png?updatedAt=1698817296779' className='w-full h-full object-cover' alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className={`${index === 0 ? 'mb-5 xs:mb-6' : (index === 6 && width <= 375) ? 'mb-0' : index === 6 ? 'mb-2.5 xs:mb-6' : index === 4 ? 'mb-4 md:mb-8' : 'xs:mb-5'}`}>
                                <div className={`${showMore[index] ? '' : index === 0 ? 'line-clamp-3 xs:line-clamp-none' : index === 1 ? 'line-clamp-3 xs:line-clamp-3' : index === 5 ? 'line-clamp-[9] xs:line-clamp-[8] md:line-clamp-[7]' : index === 7 ? 'md:line-clamp-4' : item.comments.length > 0  ? 'line-clamp-4' : 'line-clamp-[8]'} mt-2`}>
                                    {item.description.split('\n').map((line, lineIndex) => (
                                        <div key={lineIndex} className='mt-2 text-xs md:text-sm' dangerouslySetInnerHTML={{ __html: line}}/>
                                    ))}
                                </div>
                                <p onClick={() => handleShowMore(index)} className={`${index === 6 ? 'hidden' : ''} ${index === 0 || index === 4 ? 'xs:hidden' : ''} ${index === 0  ? 'mt-2 xs:mt-1' : index === 1 ? 'mt-2.5 xs:mt-2' : index === 5 ? 'mt-2 xs:mt-1.5' : index === 8 || index === 9 ? 'mt-1' :'mt-1 xs:mt-1.5'} text-[#40DA90] text-xs font-medium underline cursor-pointer`}>{showMore[index] ? 'Show Less' : 'Show More'}</p>
                            </div>
                            <div className={`${index === 6 && width <= 375 ? 'mt-2' : index === 6 ? 'mt-4' : 'mt-3'}`}>
                                <img src={item.certificate_image} className="rounded-xl" alt="" />
                            </div>
                            <div className='flex justify-between mt-2'>
                                <div className='flex items-center'>
                                    {item?.icon_image}
                                    <p className='text-xs font-medium'>{item.likes}</p>
                                </div>
                                {item.comments.length > 0 && <div className='text-xs'>
                                    {item.comments.length} {item.comments.length > 1 ? 'comments' : 'comment'}
                                </div>}
                            </div>
                            <div className={`border-t border-[#3B6EA0] ${(index === 4 || index === 0 ) && (width > 393 && width < 480) ? 'mt-2' : index === 6 && width <= 375 ? 'mt-2' : 'mt-4'}`}/>
                            <div className={`w-full ${index === 6 && width <= 375 ? 'mt-1' : item.comments.length > 0 ? 'mt-2' : ''}`}>
                                {item.comments.map((comment) => (
                                    <div key={comment.id} className='flex gap-2 pt-2 w-full'>
                                        <img src={comment.user_image} className='w-9 h-9 rounded-full' alt="" />
                                        <div className='flex flex-col rounded-b-lg rounded-tr-lg bg-[#1F3954] flex-1 p-2'>
                                            <div className='leading-[14px]'>
                                                <p className='text-[11px] font-medium'>{comment.user_name} <span className='text-white/50'>{comment.gender}</span></p>
                                                <p className='text-[9px] font-medium line-clamp-1 text-white/50'>{comment.designation}</p>
                                            </div>
                                            <div className={`text-[11px] ${index === 6 ? 'mt-1 xs:mt-2' : 'mt-2'}`} dangerouslySetInnerHTML={{ __html: comment.description }}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>))}
                </Slider>
            </div>
            
        </div>
    )
}

export default LinkdInUsers
// {