import React from 'react'

const AwardWinning = () => {
    return (
        <div className='mt-10'>
            <h2 className='text-2xl md:text-[32px] font-semibold mb-3 text-left'>Award Winning Platform</h2>
            <div className='md:hidden bg-secondary_background rounded-xl p-3'>
                <div className='flex justify-between'>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_6958.png?updatedAt=1688135296861" loading='lazy' alt="" className='w-full h-auto'/>
                    </div>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_6957.svg?updatedAt=1688135297029" loading='lazy' alt="" className='w-full h-auto'/>
                    </div>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8565.png?updatedAt=1688135297094" loading='lazy' alt="" className='w-full h-auto'/>
                    </div>
                </div>
                <p className='text-[13px] mt-4 -mb-2'>As seen on</p>
                <div className='flex justify-between items-center'>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/Group_8758.svg?updatedAt=1688986689840" loading='lazy' alt="" className='w-full h-auto' />
                    </div>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/About-us/image%209.png?updatedAt=1689922658015" loading='lazy' alt="" className='w-full h-auto' />
                    </div>
                    <div className='w-[31%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/About-us/image%20464.png?updatedAt=1689922757701" loading='lazy' alt="" className='w-full h-auto' />
                    </div>
                </div>
                <div className='w-full h-full mt-4 mb-1'>
                    <img src="https://ik.imagekit.io/238r9emirjj/About-us/Group%208725.png?updatedAt=1689922843669" loading="lazy" alt="" className='w-full h-auto'/>
                </div>
            </div>
            <div className='hidden md:flex justify-between items-center gap-3 bg-secondary_background rounded-xl p-4'>
                <div className=''>
                    <div className='flex justify-between'>
                        <div className='w-[33%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_6958.png?updatedAt=1688135296861" loading='lazy' alt="" className='w-full h-auto'/>
                        </div>
                        <div className='w-[33%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_6957.svg?updatedAt=1688135297029" loading='lazy' alt="" className='w-full h-auto'/>
                        </div>
                        <div className='w-[33%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8565.png?updatedAt=1688135297094" loading='lazy' alt="" className='w-full h-auto'/>
                        </div>
                    </div>
                    <p className='text-[13px] mt-4 -mb-2'>As seen on</p>
                    <div className='flex justify-between items-center'>
                        <div className='w-[31%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/Group_8758.svg?updatedAt=1688986689840" loading='lazy' alt="" className='w-full h-auto' />
                        </div>
                        <div className='w-[31%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/About-us/image%209.png?updatedAt=1689922658015" loading='lazy' alt="" className='w-full h-auto' />
                        </div>
                        <div className='w-[31%]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/About-us/image%20464.png?updatedAt=1689922757701" loading='lazy' alt="" className='w-full h-auto' />
                        </div>
                    </div>
                </div>
                <div className='w-full h-full'>
                    <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Frame%209679.png?updatedAt=1703313280812" loading="lazy" alt="" className='w-full h-auto'/>
                </div>
            </div>
        </div>
    )
}

export default AwardWinning