import { Outlet } from "react-router-dom";
import Logos from "../components/Logos";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { selectInfluencerDetail } from "../redux/influencersDataSlice";



const Layout = () => {

  const influencerDetail = useSelector(selectInfluencerDetail)

  return (
      <div  className={`w-full h-[100dvh] overflow-y-scroll mx-auto flex flex-col p-4 md:pt-6`} style={{background: 'radial-gradient(53.11% 56.94% at 50% 50%, #244767 0%, #172C3F 100%)'}}>
            <ToastContainer />
            {influencerDetail?.colab_logo?.url ? <img src={influencerDetail?.colab_logo?.url} width={198} height={32} alt="" className="w-[198px] h-auto mx-auto mb-4"/> : <Logos />}
            <Outlet />
      </div>
  )
}

export default Layout