import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLandingPageDetails, selectShowAnimation, selectUserDetails, setShowAnimation } from '../../redux/otherDetailsSlice'
import { selectMCQDetails } from '../../redux/mcqSlice'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { fetchCourseSyllabus } from '../../redux/postPaymentSlice'
import { selectPriceChart } from '../../redux/priceChartSlice'
import CategoryModal from '../../components/CategoryModal'
import QuizAnalysis from './QuizAnalysis'
import CourseCategories from './CourseCategories'
import OurLearnersLove from './OurLearnersLove'
import SuccessStoriesSection2 from '../NewPostPaymentFeature/SuccessStoriesSection2'
import AddonStickyBar from './AddonStickyBar'
import AddonFaq from './AddonFaq'
import useWindowSize from '../../hooks/useWindowSize'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import gift from './Animation - 1710574478545.json'
import Lottie from 'react-lottie'
import NewCourseDetailsModal from './NewCourseDetailsModal'
import FourWeekPlacementModal from './FourWeekPlacementModal'
import { selectCustomBundle, selectSelectedItems } from '../../redux/searchSlice'
import { ClipLoader, PulseLoader } from 'react-spinners'

const LottieAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: gift,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return <Lottie options={defaultOptions} />;
};


const personaliseCard = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(14).png?updatedAt=1709960017976',
        firstLine: '100% Personalised',
        secondLine: 'For You'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(15).png?updatedAt=1709960046012',
        firstLine: 'Live Doubt-',
        secondLine: 'Solving'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2075.png?updatedAt=1709960086299',
        firstLine: 'Smart AI-Backed',
        secondLine: 'Learning'
    },
]

const proMembershipCard = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Group%20(3).png?updatedAt=1710506701171',
        firstLine: 'Courses in 13+',
        secondLine: 'Categories',
        width: '22px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2076.png?updatedAt=1710506882127',
        firstLine: 'Add Certificates',
        secondLine: 'to LinkedIn',
        width: '18px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2077.png?updatedAt=1710507069829',
        firstLine: 'Verified Industry',
        secondLine: 'Projects',
        width: '14px',
        height: '16px'
    },
]

const fourWeekPlacementCard = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(16).png?updatedAt=1710575646472',
        firstLine: 'Get your CV',
        secondLine: 'Revamped',
        width: '14px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(17).png?updatedAt=1710575646395',
        firstLine: 'LinkedIn Profile',
        secondLine: 'Session',
        width: '14px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(18).png?updatedAt=1710575646317',
        firstLine: 'Interview',
        secondLine: 'Training',
        width: '14px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Group%20(4).png?updatedAt=1710575901645',
        firstLine: '5 Practice',
        secondLine: 'Interviews',
        width: '14px',
        height: '16px'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/suitcase.png?updatedAt=1715868719977',
        firstLine: 'CV Sent to 967+',
        secondLine: 'hiring partners',
        width: '14px',
        height: '16px'
    },
]

const NewPersonaliseCourseAddon = ({ minutes, seconds, price, handleTotalPrice, handleDiscountedPrice, razorpayHandler, loading, setLoading }) => {

    const landingPageDetails = useSelector(selectLandingPageDetails)
    const quizDetails = useSelector(selectMCQDetails)
    const priceChart = useSelector(selectPriceChart)
    const userDetails = useSelector(selectUserDetails)
    const showAnimation = useSelector(selectShowAnimation)
    const selectedSkills = useSelector(selectSelectedItems)
    const customBundle = useSelector(selectCustomBundle)

    const firstName = userDetails?.user_name?.split(' ')[0]

    const score = quizDetails?.quiz_score
    const { skill_name } = landingPageDetails

    const [ showPlacementModal, setShowPlacementModal ] = useState(false)

    const dispatch = useDispatch()
    const { height } = useWindowSize()

    const { personalisedSelected, handlePersonaliseSelection, proMembershipSelected, handleProMembershipSelection, threeCertificates, fiveCertificates, sevenCertificates, selectedAtleastOne, newLearners, scrollRef, fourWeekPlacementFeature, handleFourWeekPlacementSelection, setCourseName, setRating, setStudent, setShowCourseModal, setShowCategoryModal, showStickyBar2, setSelectedItems, selectedItems } = useAddonContext()

    const skillName = `${skill_name} ${(score > 50 && !skill_name?.toLowerCase()?.includes('advanced')) ? 'Advanved' : ''}`

    useEffect(() => {
        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE)
    }, [])
    console.log(selectedItems)
    useEffect(() => {
        if (selectedSkills?.length > 0 && customBundle?.id){
            setSelectedItems((prev) => {
                const filtered = prev?.filter((item) => item?.product_slug !== (skill_name.toLowerCase() === 'seo' ? 'discounted_certified_bundle_type_2' : 'custom_discounted_certified_bundle'))
                console.log("this is filtered", filtered)
                return [...filtered, {
                    product_slug:  skill_name.toLowerCase() === 'seo' ? 'discounted_certified_bundle_type_2' : 'custom_discounted_certified_bundle',
                    product_quantity: 1,
                    entity_type: 'bundle',
                    entity_id: customBundle?.id
                }]
            })
        }else{
            setSelectedItems((prev) => {
                return prev.filter((item) => item?.product_slug !== ( skill_name.toLowerCase() === 'seo' ? 'discounted_certified_bundle_type_2' : 'custom_discounted_certified_bundle'))
            })
        }
    }, [customBundle, selectedSkills, setSelectedItems, skill_name])
    
    useEffect(() => {
        if (skill_name){
            dispatch(fetchCourseSyllabus({ type: 'default', subject: skill_name, goal: `Learn ${skill_name}` }))
        }
    }, [dispatch, skill_name])

    useEffect(() => {
        if (showAnimation === 0){
            setTimeout(() => dispatch(setShowAnimation(showAnimation + 1)), 5000)
        }
    }, [dispatch, showAnimation])

    const handleAnimation = () => {
        dispatch(setShowAnimation(showAnimation + 1))
    }

    return (
        <div>
            <div className={`bg-secondary_background ${!personalisedSelected ? 'border border-[#316EAB]' : 'border border-secondary-text shadow-boxShadow'} relative cursor-pointer p-3 rounded-2xl text-white`} onClick={() => {
                handleAnimation()
                handlePersonaliseSelection()
            }}>
                {!personalisedSelected && proMembershipSelected && <>
                    <div className='top-[1px] left-1/2 transform -translate-x-1/2 absolute bg-[#316EAB] rounded-t-2xl w-[99.5%]'>
                        <p className='text-xxs text-center py-1'>Add both add-ons to become a <span className='text-secondary-text'>Top 10% Candidate</span></p>
                    </div>
                    <div className='w-full h-5'/>
                </>}
                <div className={`flex gap-3 ${!personalisedSelected ? 'items-start' : 'items-center'}`}>
                    <h2 className={`text-sm font-medium leading-4 ${personalisedSelected ? 'line-clamp-1' : ''} flex-1`}>Add a Personalised Course for {skillName}</h2>
                    <div className={`w-6 h-6 rounded-[2px] flex items-center justify-center ${personalisedSelected ? 'bg-secondary-text' : 'glow-3'}`} style={{
                        boxShadow: '0px 4px 4px 0px #00000040',
                        border: '1px solid #80C242'
                    }}>
                        {personalisedSelected && <FontAwesomeIcon icon={faCheck} className='font-semibold'/>}
                    </div>
                </div>
                {!personalisedSelected && <><div className='flex gap-2 justify-between mt-3'>
                    {personaliseCard?.map((item, index) => (
                        <div key={index} className='rounded-lg relative flex-1 px-[6px] py-3 bg-[#213A51] flex flex-col items-center text-xxs text-center leading-3' onClick={(e) => {
                            console.log(index)
                            if (index === 0){
                                e.stopPropagation()
                                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_COURSE_SYLLABUS_MODAL)
                                setRating(5)
                                setStudent(Math.floor((Math.random() * 1200) + 301))
                                setCourseName(skill_name)
                                setShowCourseModal(true)
                            }
                        }}>
                            {/* {index === 0 && <div className='absolute top-1 right-1'>
                                <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" alt="" width={16} height={16} />
                            </div>} */}
                            <div className='w-4 h-4'>
                                <img src={item.image} width={16} height={16} alt="" className='w-full h-auto'/>
                            </div>
                            <p className='mt-3'>{item.firstLine}</p>
                            <p>{item.secondLine}</p>
                        </div>
                    ))}
                </div>
                <div className='mt-3 flex items-center justify-between'>
                    <div className='font-semibold text-[#80C242]'>₹{priceChart?.[4]?.price}<span className='text-xs text-danger'> ₹<span className='line-through'>{priceChart?.[4]?.original_price}</span></span></div>
                    <div className='flex items-center gap-2'>
                        <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/fluent-mdl2_money.png?updatedAt=1709966075085" width={20} height={20} alt="" />
                        <p className='text-xs'>43% Higher Salary</p>
                    </div>
                </div></>}
            </div>
            <div className={`bg-secondary_background cursor-pointer mt-3 ${!proMembershipSelected ? 'border border-[#316EAB]' : 'border border-secondary-text shadow-boxShadow'} p-3 rounded-2xl text-white relative`} onClick={() => {
                handleAnimation()
                handleProMembershipSelection()
            }}>
                {personalisedSelected && proMembershipSelected && fourWeekPlacementFeature && <>
                    <div className='top-[1px] left-1/2 transform -translate-x-1/2 absolute bg-[#316EAB] rounded-t-2xl w-[99.5%]'>
                        <p className='text-xxs text-center py-1'>You just became a <span className='text-secondary-text'>Top 10% Candidate</span></p>
                    </div>
                    <div className='w-full h-5'/>
                </>}
                {personalisedSelected && !proMembershipSelected && <>
                    <div className='top-[1px] left-1/2 transform -translate-x-1/2 absolute bg-[#316EAB] rounded-t-2xl w-[99.5%]'>
                        <p className='text-xxs text-center py-1'>Add both add-ons to become a <span className='text-secondary-text'>Top 10% Candidate</span></p>
                    </div>
                    <div className='w-full h-5'/>
                </>}
                <div className={`flex gap-3 ${!proMembershipSelected ? 'items-start' : 'items-center'}`}>
                    <h2 className={`text-sm font-medium leading-4 flex-1 ${proMembershipSelected ? 'line-clamp-1' : ''}`}>Add Unlimited Access to 1240+ More Certified Courses</h2>
                    <div className={`w-6 h-6 rounded-[2px] flex items-center justify-center ${proMembershipSelected ? 'bg-secondary-text' : 'glow-3'}`} style={{
                        boxShadow: '0px 4px 4px 0px #00000040',
                        border: '1px solid #80C242'
                    }}>
                        {proMembershipSelected && <FontAwesomeIcon icon={faCheck} className='font-semibold'/>}
                    </div>
                </div>
                {!proMembershipSelected && <><div className='flex gap-2 justify-between mt-3'>
                    {proMembershipCard?.map((item, index) => (
                        <div key={index} className='rounded-lg relative flex-1 px-[6px] py-3 bg-[#213A51] flex flex-col items-center text-xxs text-center leading-3' onClick={(e) => {
                            console.log(index)
                            if (index === 0){
                                e.stopPropagation()
                                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_COURSE_MODAL)
                                setShowCategoryModal(true)
                            }
                        }}>
                            {index === 0 && <div className='absolute top-1 right-1'>
                                <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" alt="" width={16} height={16} />
                            </div>}
                            <div className={`w-[${item.width}] h-4`}>
                                <img src={item.image} width={16} height={16} alt="" className='w-full h-full'/>
                            </div>
                            <p className='mt-3'>{item.firstLine}</p>
                            <p>{item.secondLine}</p>
                        </div>
                    ))}
                </div>
                <div className='mt-3 flex items-center justify-between'>
                    <div className='font-semibold text-[#80C242]'>₹{priceChart?.[3]?.price}<span className='text-xs text-danger'> ₹<span className='line-through'>{priceChart?.[3]?.original_price}</span></span></div>
                    <div className='flex items-center gap-2'>
                        <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Group%209483%20(1).png?updatedAt=1700031478019" width={54} height={24} alt="" />
                        <p className='text-xs'>{newLearners()} Bought Today</p>
                    </div>
                </div></>}
            </div>
            {showAnimation >= 1 && <div className={`bg-secondary_background cursor-pointer mt-3 ${!fourWeekPlacementFeature ? 'border border-[#316EAB]' : 'border border-secondary-text shadow-boxShadow'} p-3 rounded-2xl text-white relative`} onClick={handleFourWeekPlacementSelection}>
                {personalisedSelected && proMembershipSelected && fourWeekPlacementFeature && <>
                    <div className='top-[1px] left-1/2 transform -translate-x-1/2 absolute bg-[#316EAB] rounded-t-2xl w-[99.5%]'>
                        <p className='text-xxs text-center py-1'>One step away from being <span className='text-secondary-text'>100% Job ready {firstName}!</span></p>
                    </div>
                    <div className='w-full h-5'/>
                </>}
                {(personalisedSelected || proMembershipSelected) && !fourWeekPlacementFeature && <>
                    <div className='top-[1px] left-1/2 transform -translate-x-1/2 absolute bg-[#316EAB] rounded-t-2xl w-[99.5%]'>
                        <p className='text-xxs text-center py-1'>Add all 3 add-ons to become <span className='text-secondary-text'>100% Job Ready</span></p>
                    </div>
                    <div className='w-full h-5'/>
                </>}
                <div className='flex gap-3 items-start md:items-center'>
                    <h2 className='text-sm font-medium leading-4 flex-1'>
                        Join our 4 Week Placement Training & Get Hired 3X Faster
                    </h2>
                    <div className={`w-6 h-6 rounded-[2px] flex items-center justify-center ${fourWeekPlacementFeature ? 'bg-secondary-text' : 'glow-3'}`} style={{
                        boxShadow: '0px 4px 4px 0px #00000040',
                        border: '1px solid #80C242'
                    }}>
                        {fourWeekPlacementFeature && <FontAwesomeIcon icon={faCheck} className='font-semibold'/>}
                    </div>
                </div>
                {!fourWeekPlacementFeature && <>
                    <div className='grid grid-cols-3 place-content-center gap-2 mt-3'>
                        {fourWeekPlacementCard.slice(0, 3)?.map((item, index) => (
                            <div key={index} className='rounded-lg relative flex-1 px-[6px] py-3 bg-[#213A51] flex flex-col items-center text-xxs text-center leading-3' onClick={(e) => {
                                e.stopPropagation()
                                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_PLACEMENT_MODAL)
                                setShowPlacementModal(true)
                            }}>
                                <div className='absolute top-1 right-1'>
                                    <img src='https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580' width={16} height={16} alt='' className='inline ml-1' />
                                </div>
                                <div className={`w-[${item.width}] h-4`}>
                                    <img src={item.image} width={16} height={16} alt="" className='w-full h-full'/>
                                </div>
                                <p className='mt-3 line-clamp-1'>{item.firstLine}</p>
                                <p>{item.secondLine}</p>
                            </div>
                        ))}
                    </div>
                    <div className='flex w-[70%] items-center justify-evenly mx-auto gap-2 mt-3'>
                        {fourWeekPlacementCard.slice(3)?.map((item, index) => (
                            <div key={index} className='rounded-lg relative flex-1 px-[6px] py-3 bg-[#213A51] flex flex-col items-center text-xxs text-center leading-3'  onClick={(e) => {
                                e.stopPropagation()
                                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_PLACEMENT_MODAL)
                                setShowPlacementModal(true)
                            }}>
                                <div className='absolute top-1 right-1'>
                                    <img src='https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580' width={16} height={16} alt='' className='inline ml-1' />
                                </div>
                                <div className={`w-[${item.width}] h-4`}>
                                    <img src={item.image} width={16} height={16} alt="" className='w-full h-full'/>
                                </div>
                                <p className='mt-3 line-clamp-1'>{item.firstLine}</p>
                                <p>{item.secondLine}</p>
                            </div>
                        ))}
                    </div>
                    <div className='mt-3 flex items-center justify-between'>
                        <div className='font-semibold text-[#80C242]'>₹{priceChart?.[34]?.price}<span className='text-xs text-danger'> ₹<span className='line-through'>{priceChart?.[34]?.original_price}</span></span></div>
                        <div className='flex items-center gap-2'>
                            <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/vaadin_money.png?updatedAt=1714190613537" width={24} height={24} alt="" />
                            <p className='text-xs'>34 LPA Highest Package</p>
                        </div>
                    </div>
                </>}
            </div>}
            {showAnimation === 0 && <div className='w-full mt-3'>
                <div className='w-1/3 mx-auto h-auto'>
                    <LottieAnimation />
                </div>
                <div className='text-white flex text-sm font-semibold justify-center'>
                    <p>Unlocking A <span className='text-span_color'> Secret Feature For You</span></p>
                    <PulseLoader size={3} color='#00C1FF' className='mt-2.5' />
                </div>
            </div>}
            <p className='text-sm font-medium text-center my-2 text-[#858A8F]'>Or</p>
            <div className={`rounded-xl text-[#9EB3C9] ${personalisedSelected || proMembershipSelected || fourWeekPlacementFeature ? 'opacity-50' : 'cursor-pointer'} leading-5 bg-[#213A51] border border-[#858A8F] text-center ${height <= 780 ? 'px-2 py-1.5 md:py-2.5' : 'px-2 py-2.5'}`} style={{
                boxShadow: '0px 1px 9px 0px rgba(0, 0, 0, 0.60)'
            }} onClick={(!personalisedSelected && !proMembershipSelected && !fourWeekPlacementFeature) ? () => {
                setLoading(true)
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_CONTINUE_WITH_BUTTON)
                razorpayHandler(() => setLoading(false))
            } : null}>
                {loading ? <ClipLoader size={25} color='#7FC241' /> : <>
                    <p className='text-sm md:text-xl text-[#CCE1F6]'>Continue With Only {threeCertificates ? 4 : fiveCertificates ? 6 : sevenCertificates ? 8 : selectedSkills?.length > 0 ? 1 + selectedSkills?.length : 1} Certificate{selectedAtleastOne || selectedSkills?.length > 0 ? 's' : ''}</p>
                    <p className='text-xs md:text-base text-[#9EB3C9]'>It increases your chance of standing out by {selectedSkills?.length > 0 ? `${Math.min((selectedSkills?.length + 1) * 16, 85)}%` : !selectedAtleastOne ? '30%'  : '42%'}</p>
                </>}
            </div>
            <div ref={scrollRef} className='mt-3.5 flex justify-center items-center gap-2 text-white'>
                <p className='text-xs text-[#B0C1CD] md:text-sm font-semibold'>Discount Ends In:</p>
                <p className='text-danger text-sm w-fit inline-flex justify-center text-center font-extrabold px-1 py-0.5 border-2 border-danger rounded'>{minutes.toString().padStart(2, '0')}M:{seconds.toString().padStart(2, '0')}S</p>
            </div>
            <QuizAnalysis />
            <CourseCategories />
            <div className='mt-8 text-white'>
                <h2 className='text-xl font-bold text-left mb-2'>Success Stories</h2>
                <SuccessStoriesSection2/>
            </div>
            <OurLearnersLove/>
            <AddonStickyBar 
                minutes={minutes}
                seconds={seconds}
                price={price}
                handleDiscountedPrice={handleDiscountedPrice}
                handleTotalPrice={handleTotalPrice}
                razorpayHandler={razorpayHandler}
                loading={loading} 
                setLoading={setLoading}
            />
            <AddonFaq />
            {(showStickyBar2 || personalisedSelected || proMembershipSelected || fourWeekPlacementFeature) && <div className={`w-full ${height <= 658 ? 'h-[120px]' : 'h-[104px]'}`}/>}
            <NewCourseDetailsModal
                type="personalize"
            />
            <CategoryModal/>
            <FourWeekPlacementModal
                isOpen={showPlacementModal}
                setIsOpen={setShowPlacementModal}
            />
        </div>
    )
}

export default NewPersonaliseCourseAddon