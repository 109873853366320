import React from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { ClipLoader } from 'react-spinners'

const BottomStickyBar = ({ isAtEnd, minutes, seconds, price, featurePrice, normalFeaturePrice, isOpen, setIsOpen, razorpayHandler }) => {

    const { personaliseAndCertificate, showLoader } = useAddonContext()
    const notHollow = price.price + featurePrice() > 199
    
    return (
        <div className={`fixed flex justify-between items-center bottom-0 w-full left-0 z-[9999] md:left-1/2 md:-translate-x-[50%] py-3 px-4 md:px-10 ${isAtEnd ? 'hidden' : 'bg-[#26588B]'}`}>
            <div className='flex flex-col items-start'>
                <p className='text-xs font-semibold mb-1'>{minutes ===0 && seconds === 0 ? 'Time Up. Unlock Now!' : 'Certificate Saved For:'}</p>
                <div className='border-2 border-danger text-danger flex rounded justify-center py-1 px-1.5'>
                    <p className='text-sm font-extrabold'>{minutes.toString().padStart(2, '0')}M :</p>
                    <p className='text-sm font-extrabold ml-1'>{seconds.toString().padStart(2, '0')}S</p>
                </div>
            </div>
            <div className=''>
                <button className={`${!isOpen || notHollow ? 'bg-[#7FC241] flex justify-center items-center btn--glow text-black' : 'text-[#7FC241]'} z-[999999] cursor-pointer border-2 border-[#7FC241] px-2 min-w-[184px] text-sm mb-1 font-semibold  py-2 rounded-lg focus:outline-none`} onClick={() => setIsOpen(true)}>
                    {showLoader ? <ClipLoader color='black' size={16} /> : 'Get Instant Certificate'}
                </button>
                <div className='flex justify-center items-center'>
                    <p className='text-[10px] font-semibold mr-1'>Only <span className='text-[#80C242]'>&#8377;{(personaliseAndCertificate ? (100 + price.price) : price.price) + featurePrice()}</span></p>
                    <p className='text-danger font-semibold text-[10px]'>&#8377;<span className='line-through'>{`${price.original_price + normalFeaturePrice()}`}</span></p>
                </div>
            </div>
        </div>
    )
}

export default BottomStickyBar