import React from 'react'
import { useNavigate } from 'react-router-dom'

const Logos = () => {
  const navigate = useNavigate()
  return (
    <div className='w-fit h-[40px] bg-white flex items-center py-2 mx-auto rounded-md mb-4'>
        <div className='w-[1.75rem] h-[1.75rem] mx-1.5' onClick={() => navigate('/')}>
            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_11.png?updatedAt=1685175860985" loading="eager" alt="" className='w-full h-full'/>
        </div>
        <div className='pr-2'>
            <p className='font-medium text-sm'>LearnTube</p>
            <p className='text-xs -mt-1'>by CareerNinja</p>
        </div>
    </div>
  )
}

export default Logos