import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { axiosNoAuth } from '../config/axiosInstances';


// Action to fetch questions asynchronously
export const getRelatedSkills = createAsyncThunk(
    'multiSkillSelection/sendMultiSkillSelectionDetails',
    async ({  subject_name }) => {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/similar_skills_list', { subject_name, n: 13 })
            const searchResponse = await axiosNoAuth.post('https://ninjacurate.learntube.ai/fast_search',
            {
                skill: subject_name
            })
            return [ ...searchResponse.data, ...response.data?.similar_list]
        } catch (err) {
            console.log(err)
            throw err
        }        
    }
)

const initialState = {
    skillList: []
}

// Redux slice for questions
const multiSkillSelectionSlice = createSlice({
    name: 'multiSkillSelection',
    initialState,
    reducers: {
        resetInitialState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getRelatedSkills.fulfilled, (state, action) => {
            state.skillList = action.payload
        })
    },
})

export const { resetInitialState } = multiSkillSelectionSlice.actions
export const selectRelatedSkills = (state) => state.multiSkillSelection.skillList
export default multiSkillSelectionSlice.reducer;