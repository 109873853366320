import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { useSelector } from 'react-redux'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { toast } from 'react-toastify'
import { placementData } from '../../utilities/constants'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import ReactPlayer from 'react-player'

const placementModalData  = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(3).png?updatedAt=1712666131067',
        title: 'Get your CV Revamped',
        description: 'Updated ATS friendly CV for 5x more interview calls'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/devicon_linkedin.png?updatedAt=1709878343522',
        title: 'LinkedIn Profile Upgrade',
        description: 'Users report get 63% more recruiter connects'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/Lead%20Generation/Vector%20(4).png?updatedAt=1712666881754',
        title: 'Interview Training',
        description: 'Increase your chance of cracking inteviews by 46%'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2077.png?updatedAt=1712660855640',
        title: '5 Practice Interviews',
        description: 'Pass tech rounds & negotiate for 56% higher CTC'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/Lead%20Generation/Vector%20(5).png?updatedAt=1712667124358',
        title: 'Get CV Sent to 967+ hiring partners',
        description: '93% placement rate with top MNCs & startups'
    },
]

const FourWeekPlacementModal = ({ isOpen, setIsOpen }) => {

    const priceChart = useSelector(selectPriceChart)

    const [ showVideo, setShowVideo ] = useState(false)
    const [ videoUrl, setShowVideoUrl ] = useState('')

    const { setFourWeekPlacementFeature, fourWeekPlacementFeature } = useAddonContext()

    const closeModal = () => {
        setShowVideo(false)
        setIsOpen(false)
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-10 bg-black opacity-80" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-secondary_background max-w-xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll px-3 py-3 border border-[#316EAB]`}
                    style={{
                        boxShadow: '0px 0px 20px 0px #0000004D'
                    }}
                >
                    {!showVideo ? <>
                        <div className='flex justify-between'>
                            <h3 className='font-semibold leading-5'>Join our 4 Week Placement Training & Get Hired 3X Faster</h3>
                            <div className='cursor-pointer' onClick={() => setIsOpen(false)}>
                                <FontAwesomeIcon icon={faXmark} className='text-2xl md:text-2xl'/>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 mt-5'>
                            {placementModalData?.map((item, index) => (
                                <div key={index} className='bg-[#213A51] rounded-xl flex items-center gap-3 px-3 py-2 cursor-pointer' onClick={() => {
                                    setShowVideo(true)
                                    setShowVideoUrl(placementData?.[index < 4 ? index : 3]?.video_link)
                                }}>
                                    <div className='w-[70px] h-full relative'>
                                        <img src={placementData?.[index < 4 ? index : 3]?.thumbnail} width={70} height={40} className='w-full h-auto' alt="" />
                                        <FontAwesomeIcon icon={faPlayCircle} className='absolute inset-0 m-auto'/>
                                    </div>
                                    <div className='flex-1'>
                                        <h3 className='text-xs mb-0.5'>{item.title}</h3>
                                        <p className='text-xxs text-[#B0C1CD]'>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='flex justify-between items-center mt-6'>
                            <p className=''><span className='text-[#80C242] text-lg font-semibold'>₹{priceChart?.[34]?.price}</span> <span className='text-[#FF4646] font-semibold'>₹</span><span className='text-[#FF4646] line-through'>{priceChart?.[34]?.original_price}</span></p>
                            <div className='w-1/2 bg-shadow text-center py-3 text-black text-sm font-medium rounded-lg cursor-pointer' onClick={() => {
                                if (fourWeekPlacementFeature){
                                    toast.success("Already In Cart")
                                }else{
                                    setFourWeekPlacementFeature(true)
                                    toast.success("Added to Cart")
                                }
                            }}>
                                Add To Cart
                            </div>
                        </div>
                    </> : <div className='bg-[#2D4F71] max-w-xl rounded-2xl mx-auto my-auto z-50 w-full relative'>
                        <div className='flex justify-between items-center mb-3 px-2'>
                            <FontAwesomeIcon icon={faChevronLeft} className='text-2xl cursor-pointer' onClick={() => setShowVideo(false)}/>
                            <FontAwesomeIcon icon={faXmark} className='text-3xl cursor-pointer' onClick={closeModal}/>
                        </div>
                        <ReactPlayer
                            url={videoUrl}
                            config={{
                            file: {
                                attributes: {
                                preload: 'auto',
                                },
                            },
                            youtube: {
                                playerVars: { modestbranding: 1, rel: 0 },
                            },
                            }}
                            width={"100%"}
                            height={
                            window.innerWidth > 768
                                ? window.innerWidth * (9 / 16) * (5 / 12)
                                : window.innerWidth * (9 / 16)
                            }
                            controls={true}
                            playing={true}
                        />
                    </div>}
                </div>
            </Dialog>
        </div>
    )
}

export default FourWeekPlacementModal