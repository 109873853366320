import { useState, useEffect, useRef, useCallback } from 'react'
import constants from '../../utilities/constants'
import OurLearnersWorkAt from './OurLearnersWorkAt'
import UsersModal from './UsersModal'
import { RAZORPAY_KEY } from '../../config/api_config'
import useRazorpay from 'react-razorpay'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Mixpanel } from '../../config/Mixpanel'
import { createPaymentOrder } from '../../redux/razorPaySlice'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRelatedJobs, selectLandingPageDetails, selectUserDetails, setUserDetails, setUserSkillDetails } from '../../redux/otherDetailsSlice'
import LinkdInUsers from './LinkdInUsers'
import BottomStickyBar from './BottomStickyBar'
import Pricingcard from './Pricingcard'
import Timer from './Timer'
import CertificateRecognition from './CertificateRecognition'
import CertificateHelp from './CertificateHelp'
import AwardWinning from './AwardWinning'
import BottomPricingcard from './BottomPricingcard'
import ShowCancellation from './ShowCancellation'
import FlightStyleFeatureCollection from './FlightStyleFeatureCollection'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { getUserFullDetails, selectMCQDetails, selectTypeformData, setPaidStatus } from '../../redux/mcqSlice'
import { getUserDetails, setAuthToken } from '../../redux/authenticationSlice'
import { getProductDetails, selectPriceChart } from '../../redux/priceChartSlice'
import CertifiedFAQSection from './CertifiedFAQSection'
import { toast } from 'react-toastify'
import { getRelatedSkills } from '../../redux/multiSkillSelectionSlice'
import { fetchCourseSyllabus } from '../../redux/postPaymentSlice'
import { selectLargeComboPacks } from '../../redux/largerComboPackSlice'
import { selectSelectedItems } from '../../redux/searchSlice'


const costlyCertificates = ["azure", "growth marketing", "aws"]
const cheapCertificates = ["scrum master", "sql", "advanced excel", "artificial intelligence", "data analytics", "mongo db", "certified professional data engineer", "certified aws solutions architect", "certified project management professional", "certified scrummaster", "microsoft azure cloud professional", "business analyst", "expert certification in salesforce deployment", "certified information systems security professional", "certified supply chain manager", "docker & kubernetes", "fundamentals of docker", "fundamentals of kubernetes", "python", "mobile developer"]
export const nineNintyNine = ["business management", "hr management", "supply chain management", "hospitality management", "strategic management", "business project management"]
export const fourNinetyNine = ["seo", "financial analyst", "jira", "excel", "digital marketing", "tally"]

const settings = {
    dots: false,
    slidesToShow: 3,
    rows: 2,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                infinite: true,
                dots: false,
                arrows: false,
                swipeToSlide: false,
            }
        },
    ]
};

const AnalysisPage = () => {

    const userDetails = useSelector(selectUserDetails)
    const landingPage = useSelector(selectLandingPageDetails)
    const typeformData = useSelector(selectTypeformData)
    const quizDetails = useSelector(selectMCQDetails)
    const priceChart = useSelector(selectPriceChart)
    const comboPacks = useSelector(selectLargeComboPacks)
    const selectedSkills = useSelector(selectSelectedItems)
    const location = useLocation()
    
    const params = new URLSearchParams(location.search)
    const utm_source = params.get('utm_source')

    const { beginnerPackSelected, interMediatePackSelected, advancedPackSelected } = comboPacks
    const userName = userDetails?.user_name
    const skill_name = landingPage?.skill_name
    const email = userDetails?.email
    const phoneNo = userDetails?.phone_no
    const score = quizDetails?.quiz_score

    const [price, setPrice] = useState({})
    const [minutes, setMinutes] = useState(15)
    const [seconds, setSeconds] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showLoader1, setShowLoader1] = useState(false)
    const [showLoader3, setShowLoader3] = useState(false)
    const [ showAdditionalFeature, setShowAdditionalFeature ] = useState(false)
    const [showCancellationScreen, setShowCancellationScreen] = useState(false)
    
    const [isAtEnd, setIsAtEnd] = useState(false)
    const navigate = useNavigate()
    const sentinelRef = useRef(null);
    const Razorpay = useRazorpay()
    const dispatch = useDispatch()
    const scrollRef = useRef(null)
    const { token } = useParams()

    const { threeCertificates, fiveCertificates, sevenCertificates,proMembershipSelected, personalisedSelected, fourWeekPlacementFeature, selectedItems, setSelectedItems } = useAddonContext()

    const skillIsBusinessManagement = nineNintyNine?.includes(skill_name?.toLowerCase())

    useEffect(()=>{
        const fetchData = async () => {
            try {
                dispatch(setAuthToken(token))
                const response = await dispatch(getUserDetails(token)).unwrap()
                const { certified_user_skill_id, name, email, phone_number } = response
                dispatch(setUserDetails({ name, email, phone_number }))
                const userDetailsResponse = await dispatch(getUserFullDetails({ certified_user_skill_id })).unwrap()
                const { subject_name } = userDetailsResponse
                dispatch(setUserSkillDetails(subject_name))
                Mixpanel.identify(email)
                Mixpanel.track(constants.Mixpanel.SAVED_ANALYSIS_PAGE ,{
                    distinct_id: email
                })
                Mixpanel.people.set({
                    "name": name,
                    "phoneNo": phone_number,
                    "skillName": subject_name
                })
            } catch (error) {
                console.log(error.message)
            }
        }
        if (token){
            fetchData()
        }else{
            Mixpanel.identify(email)
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE, {
                distinct_id: email
            })
            Mixpanel.people.set({
                "name": userName,
                "phoneNo": phoneNo,
                "skillName": skill_name
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchdata = async () => {
            try {
                dispatch(getProductDetails({ certified_product_id: 2 }))
                dispatch(getProductDetails({ certified_product_id: 3 }))
                dispatch(getProductDetails({ certified_product_id: 4 }))
                dispatch(getProductDetails({ certified_product_id: 9 }))
                dispatch(getProductDetails({ certified_product_id: 10 }))
                dispatch(getProductDetails({ certified_product_id: 11 }))
                dispatch(getProductDetails({ certified_product_id: 21 }))
                dispatch(getProductDetails({ certified_product_id: 31 }))
                dispatch(getProductDetails({ certified_product_id: 32 }))
                dispatch(getProductDetails({ certified_product_id: 33 }))
                dispatch(getProductDetails({ certified_product_id: 34 }))
                dispatch(getProductDetails({ certified_product_id: 37 }))
                dispatch(getProductDetails({ certified_product_id: 38 }))
                dispatch(getProductDetails({ certified_product_id: 39 }))
                dispatch(getProductDetails({ certified_product_id: 40 }))
                
                if (skillIsBusinessManagement){
                    const response = await dispatch(getProductDetails({ certified_product_id: 35 }))
                    setPrice(response?.payload?.data)
                }else if (fourNinetyNine.includes(skill_name?.toLowerCase())){
                    const response = await dispatch(getProductDetails({ certified_product_id: 36 }))
                    setPrice(response?.payload?.data)
                }else if (landingPage?.utm_source === 'thedataschoool' && skill_name === 'SQL'){
                    const response = await dispatch(getProductDetails({ certified_product_id: 1 }))
                    setPrice(response?.payload?.data)
                }else if (costlyCertificates?.includes(skill_name?.toLowerCase())){
                    const response = await dispatch(getProductDetails({ certified_product_id: 16 }))
                    setPrice(response?.payload?.data)
                }else if (cheapCertificates?.includes(skill_name?.toLowerCase())){ 
                    const response = await dispatch(getProductDetails({ certified_product_id: 17 }))
                    setPrice(response?.payload?.data)
                } 
                else {
                    const response = await dispatch(getProductDetails({ certified_product_id: 1 }))
                    setPrice(response?.payload?.data)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        fetchdata()
        
    }, [dispatch, skill_name, skillIsBusinessManagement, landingPage?.utm_source])

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchRelatedJobs({ subject: skill_name }))
                dispatch(getRelatedSkills({ subject_name: skill_name }))
                dispatch(fetchCourseSyllabus({ type: 'default', subject: skill_name, goal: `Learn ${skill_name}` }))
            } catch (error) {
                toast.error('Something Went Wrong!')
            }
        }
        if (skill_name){
           fetchData()
        }
    }, [dispatch, skill_name])

    useEffect(() => {
        setSelectedItems([{
            product_slug: price?.product_slug,
            product_quantity: 1,
            entity_type: 'skill',
            entity_id: typeformData?.id
        }])
    }, [price, setSelectedItems, typeformData?.id])

    const handleTotalPrice = useCallback(() => {
        let sum1 = threeCertificates ?  (skillIsBusinessManagement ? priceChart?.[37]?.original_price : priceChart?.[2]?.original_price) : 0
        let sum2 = fiveCertificates ? (skillIsBusinessManagement ? priceChart?.[38]?.original_price : priceChart?.[10]?.original_price) : 0
        let sum3 = sevenCertificates ? (skillIsBusinessManagement ? priceChart?.[39]?.original_price : priceChart?.[11]?.original_price) : 0
        let sum4 = personalisedSelected ? priceChart?.[4]?.original_price : 0
        let sum5 = proMembershipSelected ? priceChart?.[3]?.original_price : 0
        let sum6 = fourWeekPlacementFeature ? priceChart?.[34]?.original_price : 0
        let sum7 = beginnerPackSelected ? priceChart?.[31]?.original_price : 0
        let sum8 = interMediatePackSelected ? priceChart?.[32]?.original_price : 0
        let sum9 = advancedPackSelected ? priceChart?.[33]?.original_price : 0
        let sum10 = selectedSkills?.length > 0 ? selectedSkills?.length * (skill_name.toLowerCase() === 'seo' ? 499 : 199) : 0
        
        return sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8 + sum9 + sum10
    }, [advancedPackSelected, beginnerPackSelected, fiveCertificates, fourWeekPlacementFeature, interMediatePackSelected, personalisedSelected, priceChart, proMembershipSelected, selectedSkills?.length, sevenCertificates, skillIsBusinessManagement, skill_name, threeCertificates])

    const handleDiscountedPrice = useCallback(() => {
        let sum1 = threeCertificates ?  (skillIsBusinessManagement ? priceChart?.[37]?.price : priceChart?.[2]?.price) : 0
        let sum2 = fiveCertificates ? (skillIsBusinessManagement ? priceChart?.[38]?.price : priceChart?.[10]?.price) : 0
        let sum3 = sevenCertificates ? (skillIsBusinessManagement ? priceChart?.[39]?.price : priceChart?.[11]?.price) : 0
        let sum4 = personalisedSelected ? priceChart?.[4]?.price : 0
        let sum5 = proMembershipSelected ? priceChart?.[3]?.price : 0
        let sum6 = fourWeekPlacementFeature ? priceChart?.[34]?.price : 0
        let sum7 = beginnerPackSelected ? priceChart?.[31]?.price : 0
        let sum8 = interMediatePackSelected ? priceChart?.[32]?.price : 0
        let sum9 = advancedPackSelected ? priceChart?.[33]?.price : 0
        let sum10 = selectedSkills?.length > 0 ? (Math.round((selectedSkills?.length * (skill_name.toLowerCase() === 'seo' ? 499 : 199) * (1 - (Math.min(selectedSkills?.length * 5 /100, 0.5)))))) : 0

        return sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7 + sum8 + sum9 + sum10
    }, [advancedPackSelected, beginnerPackSelected, fiveCertificates, fourWeekPlacementFeature, interMediatePackSelected, personalisedSelected, priceChart, proMembershipSelected, selectedSkills?.length, sevenCertificates, skillIsBusinessManagement, skill_name, threeCertificates])

    useEffect(() => {
        if (typeformData?.is_paid){
            return navigate('/analysis/leaderboard/v2')
        }
    }, [navigate, typeformData?.is_paid])

    useEffect(() => {
        
        let interval = null;
        const updateTimer = () => {
        if (minutes === 0 && seconds === 0) {
            clearInterval(interval);
        } else {
            if (seconds === 0) {
                setMinutes((prevMinutes) => prevMinutes - 1);
                setSeconds(59);
            } else {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }
        }};

        interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    
    }, [minutes, seconds]);

    useEffect(() => {
        const sentinel = sentinelRef.current;
        const observer = new IntersectionObserver((entries) => {
            // If the sentinel comes into view, setIsAtEnd to true
            if (entries[0].isIntersecting) {
                setIsAtEnd(true);
            } else {
                setIsAtEnd(false);
            }
        });

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => {
            if (sentinel) {
                observer.unobserve(sentinel);
            }
        }
    }, [sentinelRef, showAdditionalFeature]);

    useEffect(() => {
        if (
            // showAdditionalFeature ||
            showCancellationScreen){
            scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [showAdditionalFeature, showCancellationScreen])

    const razorpayHandler = async (callback) => {
        try {
            const response = await dispatch(createPaymentOrder({ items: selectedItems, utm_source: utm_source ? utm_source : "" })).unwrap()
            if (!response?.razorpay_order_id){
                throw new Error('Something Went Wrong!')
            }
            const options = {
                key: RAZORPAY_KEY,
                amount: `${ response?.order_cost * 100}`,
                currency: "INR",
                name: userName,
                image: "",
                order_id: response?.razorpay_order_id,
                modal: {
                    "ondismiss": function () {
                        callback()
                        setShowAdditionalFeature(false)
                        setShowCancellationScreen(true)
                    }
                },
                handler: (res) => {
                    getOrderStatusSuccess(res)
                    callback();
                },
                prefill: {
                    name: userName,
                    email: email,
                    contact: phoneNo,
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#26CBA1"
                }
            };
            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
                getOrderStatusFailed(response)
                callback()
              });
            rzp1.open();
        } catch (error) {
            console.log(error)
            toast.error('Something Went Wrong! Try Again')
        }finally{
            callback()
        }
    }
    
    const getOrderStatusSuccess = async (response) => {

        window.dataLayer.push({
            'event': 'Purchase',
            'pageTitle': 'Successful Payment Page', //some arbitrary name for the page/state
            'pageUrl': 'https://certified.learntube.ai/analysis/leaderboard/v2'
        })
        dispatch(setPaidStatus())
        navigate('/analysis/leaderboard/v2')
    }

    const getOrderStatusFailed = async (response) => {
        setShowAdditionalFeature(false)
        setShowCancellationScreen(true)
    }

    return (
        <>
            {!showAdditionalFeature ? <div className='flex-1 max-w-3xl mx-auto text-white text-center w-full relative'>
                {!showCancellationScreen && <BottomStickyBar
                    isAtEnd={isAtEnd}
                    minutes={minutes}
                    seconds={seconds}
                    email={email}
                    setShowLoader3={setShowLoader3}
                    razorpayHandler={razorpayHandler}
                    showLoader3={showLoader3}
                    price={price}
                    featurePrice={handleDiscountedPrice}
                    skill_name={skill_name}
                    normalFeaturePrice={handleTotalPrice}
                    isOpen={showAdditionalFeature}
                    setIsOpen={setShowAdditionalFeature}
                />}
                <div className='h-[1px] w-[1px] absolute -top-[150px]' ref={scrollRef}></div>
                    <h2 className='text-[32px] lg:text-[44px] lg:leading-[50px] font-medium leading-[33px] mt-5 md:mt-3'>Congrats {userName}! <span className='font-semibold text-span_color'>You’ve Passed!</span> 🚀</h2>
                    
                <div className='max-w-xl mx-auto'>
                    <Pricingcard
                        score={score}
                        skill_name={skill_name}
                        razorpayHandler={razorpayHandler}
                        price={price}
                        featurePrice={handleDiscountedPrice}
                        setIsOpen={setShowAdditionalFeature}
                        normalFeaturePrice={handleTotalPrice}
                        email={email}
                    />
                    <Timer
                        minutes={minutes}
                        seconds={seconds}
                    />
                    <div className='w-full mt-6 md:mt-10'>
                        <h2 className='text-2xl md:text-[32px] font-semibold text-left mb-4 md:mb-5 leading-[30px] md:leading-[34px]'>Certificate Recognised By 1100+ Companies</h2>
                        <CertificateRecognition
                            settings={settings}
                        />
                    </div>
                    <CertificateHelp/>
                    <div className='text-left mt-6 md:mt-10'>
                        <h3 className='text-2xl md:text-[32px] font-semibold mb-3'>Our Learners Work At</h3>
                        <OurLearnersWorkAt/>
                        
                        <p className='opacity-50 text-sm font-semibold text-center mt-2 underline cursor-pointer' onClick={()=>setIsModalOpen(true)}>View 1000s more</p>
                        <UsersModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                        />
                    </div>

                    <div className='text-left mt-6 md:mt-10'>
                        <h3 className='text-2xl md:text-[32px] font-semibold mb-3'>Stand Out From The Crowd</h3>
                        <LinkdInUsers/>
                    </div>
                    <AwardWinning/>
                    <BottomPricingcard
                        email={email}
                        setShowLoader1={setShowLoader1}
                        razorpayHandler={razorpayHandler}
                        showLoader1={showLoader1}
                        skill_name={skill_name}
                        setIsOpen={setShowAdditionalFeature}
                    />
                    <div className='h-10'></div>
                    <ShowCancellation
                        isOpen={showCancellationScreen}
                        setIsOpen={setShowCancellationScreen}
                        razorpayHandler={razorpayHandler}
                        setShowAdditionalFeature={setShowAdditionalFeature}
                    />
                    <CertifiedFAQSection
                        sentinelRef={sentinelRef}
                    />
                </div>
            </div> : 
            <FlightStyleFeatureCollection
                setIsOpen={setShowAdditionalFeature}
                minutes={minutes}
                seconds={seconds}
                price={price}
                handleDiscountedPrice={handleDiscountedPrice}
                handleTotalPrice={handleTotalPrice}
                razorpayHandler={razorpayHandler}
                skillName={skill_name}
            />
            }
        </>
        
    )
}

export default AnalysisPage