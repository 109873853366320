import React from 'react'
import Slider from 'react-slick'
import { ourLearnersLove } from '../../utilities/constants'

const settings = {
    slidesToShow: 1,
    rows: 1,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 2000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                infinite: true,
                arrows: false,
                swipeToSlide: false,
            }
        },
    ]
}

const OurLearnersLove = () => {
    return (
        <div className='mt-6 text-white'>
            <h2 className='text-xl font-bold mb-2'>What Our Learners Love</h2>
            <Slider {...settings}>
                {ourLearnersLove.map((item, index) => (
                    <div key={index} className='w-full'>
                        <div className='w-[99%] h-[190px] bg-secondary_background rounded-2xl p-4 flex flex-col justify-center items-center gap-2'>
                            <div className='text-sm text-center'>{item.content}</div>
                            <div className='flex gap-2 items-center'>
                                <img src={item.image} alt="" className='w-8 h-8 object-cover rounded-full'/>
                                <div>
                                    <p className='text-sm'>{item.name}</p>
                                    <p className='text-xs text-white/70'>{item.skill}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default OurLearnersLove