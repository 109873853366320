import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClipLoader } from 'react-spinners'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const BottomPricingcard = ({ showLoader1, setIsOpen }) => {

    return (
        <div className='bg-secondary_background flex flex-col items-center border-2 border-[#7FC241] rounded-xl p-3 mt-6'>
            <div className='flex flex-col items-center md:flex-row'>
                <div className='w-[50%] md:w-[213px] flex-shrink-0 -mt-2 -mb-2'>
                    <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Businessman_personal_goal_with_gold_trophy_and_dartboard_1.png?updatedAt=1688111367452" alt="" className='w-full h-auto' />
                </div>
                <h2 className='text-[32px] leading-[38px] mb-3'><span className='text-span_color font-bold'>Unlock Certificate</span> And Upgrade Your Career</h2>
            </div>
            <div className='w-[1px] h-[1px]'></div>
            <div className='w-full md:w-[80%]'>
                <div className='font-semibold flex justify-center items-center mx-auto w-[90%] md:w-3/4 md:mt-3 rounded-xl btn--glow text-black bg-[#7FC241] cursor-pointer py-3 mb-3 mt-1' onClick={() => setIsOpen(true)}>
                    {showLoader1 ?<ClipLoader color='black' size={20} /> : 'Get Instant Certificate'}
                    {!showLoader1 && <FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 arrow-move'/>}
                </div>
                
                <div className='w-3/4 md:w-1/2 h-full mx-auto -mt-2 pb-4'>
                    <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8715.png?updatedAt=1688111669108" alt="" className='w-full h-auto'/>
                </div>
            </div>
        </div>
    )
}

export default BottomPricingcard