import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { selectMcqAnalysis, selectTypeformData } from '../../redux/mcqSlice'

const QuizAnalysis = () => {

    const analysis = useSelector(selectMcqAnalysis)
    const typeformData = useSelector(selectTypeformData)
    
    const [ analysisUserDoNotKnow, setAnalysisUserDoNotKnow ] = useState([])
    const { setPersonalisedSelected, personalisedSelected, setSelectedItems } = useAddonContext()
    const userDetails = useSelector(selectUserDetails)
    const email = userDetails?.email

    useEffect(() => {
        const tempArray = analysis?.user_weak_areas?.split("\n")?.filter((item) => item.length > 0)
        
        if (tempArray?.length > 4){
            setAnalysisUserDoNotKnow(tempArray?.slice(0,4))
        }else{
            setAnalysisUserDoNotKnow(tempArray)
        }
    }, [analysis])

    console.log("this is", analysis)

    return (
        <>{analysis && <div className='text-white mt-6'>
            <h2 className='text-xl md:text-[32px] md:leading-8 font-bold text-left leading-6'>Based On Your Quiz Analysis, We’ve Personalised Your Course</h2>
            <div className='bg-secondary_background rounded-2xl py-4 px-3 mt-3'>
                <h3 className='text-base font-medium mb-4'>Includes Topics You Don’t Know</h3>
                {analysisUserDoNotKnow?.map((item, index) => (
                    (item?.length > 0 ? <div key={index} className={`bg-[#213A51] flex items-center px-2.5 ${analysisUserDoNotKnow?.length === 1 ? 'rounded-xl' : index === 0 ? 'rounded-t-xl' : index === analysisUserDoNotKnow?.length -1 ? 'rounded-b-xl' : ''}`}>
                        <div className={`w-full flex gap-2.5 items-center ${analysisUserDoNotKnow?.length -1 !== index ? 'border-b border-[#1E6AB6]' : ''} py-3`}>
                            <div className='w-[24px] h-[24px]'>
                                <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/material-symbols-light_cancel-outline.png?updatedAt=1710508814155" width={24} height={24} alt="" className='w-full h-auto'/>
                            </div>
                            <p className='flex-1 text-sm'>{item}</p>
                        </div>
                </div> : 0)
                ))}
                <div className='flex gap-1 justify-between items-center mt-4'>
                    <h3 className='text-sm flex-1'>+Top Advance Concepts</h3>
                    <div className='border flex gap-2 items-center cursor-pointer text-[#7FC241] rounded-lg border-[#7FC241] p-2' onClick={() => {
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ANALYSIS_BUTTON, {
                            distinct_id: email
                        })
                        if (personalisedSelected){
                            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'personalized'))
                        }else{
                            setSelectedItems(prev => [...prev, {
                                product_slug: 'personalized',
                                product_quantity: 1,
                                entity_type: 'skill',
                                entity_id: typeformData?.id
                            }])
                        }
                        setPersonalisedSelected(prev => !prev)
                    }}>
                        <div className={`w-6 flex-shrink-0 h-6 flex items-center justify-center rounded-[2px] shadow border border-lime-500 ${personalisedSelected ? 'bg-[#7FC241]' : 'glow-2'}`} style={personalisedSelected ? {
                            boxShadow: '0px 0px 4px 3px rgba(127, 194, 65, 0.25)'
                        } : {}}>
                            {personalisedSelected && <FontAwesomeIcon icon={faCheck} className='font-bold text-white text-base'/>}
                        </div>
                        Add to cart
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default QuizAnalysis