import Stepper from '../../components/Stepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { useRef } from 'react'
import NewMultipleCertificateAddon from './NewMultipleCertificateAddon'
import NewPersonaliseCourseAddon from './NewPersonaliseCourseAddon'
import { ClipLoader } from 'react-spinners'
import { selectTypeformData } from '../../redux/mcqSlice'
import { customCertifiedBundle, selectSelectedItems } from '../../redux/searchSlice'

const FlightStyleFeatureCollection = ({ setIsOpen, minutes, seconds, price, handleDiscountedPrice, handleTotalPrice, razorpayHandler, skillName }) => {

    const userDetails = useSelector(selectUserDetails)
    const selectedSkills = useSelector(selectSelectedItems)
    const typeformData = useSelector(selectTypeformData)

    const email = userDetails?.email

    const { threeCertificates, fiveCertificates, sevenCertificates, step, handleBackward, handleForward, selectedAtleastOne, setShowStickyBar2, proMembershipSelected, personalisedSelected, skillIsNotSeo } = useAddonContext()
    
    const scrollRef = useRef()
    const scrollRef2 = useRef(null)

    const [ loading, setLoading ] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (scrollRef.current){
            scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    },[step])

    useEffect(() => {
        const scroll = scrollRef2.current
        const observer = new IntersectionObserver((entries) => {
            if(entries[0].isIntersecting){
                setShowStickyBar2(false)
            }else{
                setShowStickyBar2(true)
            }
        })

        if (scrollRef2.current){
            observer.observe(scrollRef2.current)
        }

        return () => {
            if(scroll){
                observer.unobserve(scroll)
            }
        }

    }, [scrollRef2, proMembershipSelected, personalisedSelected, setShowStickyBar2, step])

    
    return (
        <div className='relative flex-1 w-full mx-auto max-w-2xl'>
            <div ref={scrollRef} className="h-[1px] w-[1px] absolute -top-[150px]"></div>
            <div className='absolute -top-12 left-2 cursor-pointer' onClick={() => handleBackward(setIsOpen)}>
                <FontAwesomeIcon icon={faChevronLeft} className='text-2xl text-white'/>
            </div>
            <div className='absolute -top-12 right-2 text-[#7FC241] text-sm md:text-lg underline cursor-pointer' onClick={() => {
                if (step === 1){
                    if (selectedAtleastOne){
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_TOP_MOVE_FORWARD_THIS_BUTTON, {
                            distinct_id: email
                        })
                    }else{
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_TOP_SKIP_THIS_BUTTON, {
                            distinct_id: email
                        })
                    }
                    if (!skillIsNotSeo){
                        const skillArray = selectedSkills.map((item) => {
                            return { course_name: item }
                        })
                        dispatch(customCertifiedBundle({ course_list: skillArray, bundle_type: skillName.toLowerCase() === 'seo' ? 'discounted_certified_bundle_type_2' : 'custom_discounted_certified_bundle', certified_user_skill_id: typeformData?.id }))
                    }
                    handleForward()
                }
                else {
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_SKIP_THIS_BUTTON, {
                        distinct_id: email
                    })
                    setLoading(true)
                    razorpayHandler(() => setLoading(false))
                }
            }}>
                {step === 2 && loading ? <ClipLoader size={20} color='#7FC241' /> : !(step === 1 && (threeCertificates || fiveCertificates || sevenCertificates)) ? 'Skip' : <FontAwesomeIcon icon={faChevronRight} className='text-2xl text-white' />}
            </div>
            <Stepper
                handleTotalPrice={handleTotalPrice}
                handleDiscountedPrice={handleDiscountedPrice}
                price={price}
            />
            <h1 className='text-xl font-bold text-white text-center leading-[26px] mt-4 mb-4'>{step === 1 ? <span>Add More Certificates & Get Hired <span className='text-span_color'>2x Faster</span></span> : <span ref={scrollRef2}>Choose Specialized Courses & Become <span className='text-span_color'>100% Job-Ready</span></span>}</h1>
            {step === 1 ? <NewMultipleCertificateAddon
                            minutes={minutes} 
                            seconds={seconds}
                            handleTotalPrice={handleTotalPrice}
                            handleDiscountedPrice={handleDiscountedPrice}
                            price={price}
                            skillName={skillName}
                        />
            : <NewPersonaliseCourseAddon
                minutes={minutes}
                seconds={seconds}
                price={price}
                handleTotalPrice={handleTotalPrice}
                handleDiscountedPrice={handleDiscountedPrice}
                razorpayHandler={razorpayHandler}
                loading={loading}
                setLoading={setLoading}
            />}
        </div>
    )
}

export default FlightStyleFeatureCollection