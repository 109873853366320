import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const NewProjectsAndOutcomes = ({ list }) => {
    return ( 
        list?.slice(0, 5)?.map((item, index) => (
            item?.length > 3 && <div key={index} className={`bg-[#213A51] ${index === 0 ? 'rounded-t-xl' : index === list?.slice(0,5)?.length - 1 ? 'rounded-b-xl' : ''} px-2 py-2.5 flex items-center gap-2`}>
                <div className='w-4 h-4 bg-shadow rounded-full flex items-center justify-center'>
                    <FontAwesomeIcon icon={faCheck} className='text-sm' />
                </div>
                <p className='text-sm font-medium flex-1'>{item?.slice(3)}</p>
            </div>
        ))
    )
}

export default NewProjectsAndOutcomes