import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";


const Accordian = ({ title, content, index, length }) => {

    const [isActive, setIsActive] = useState(false);
    

return (
    <>
        <div key={index} className={`${isActive === false ? "flex flex-col justify-center px-3 py-3" : "flex flex-col justify-center  px-3 py-3"} bg-[#2D4F71] ${index === 0 ? 'rounded-t-xl' : index === length -1 ? 'rounded-b-xl' : ''}`}>
            <div className="flex flex-row justify-between items-center w-full" onClick={(event) => {setIsActive(!isActive)}}>
                <div className="text-base leading-5 tracking-wide font-medium text-left cursor-pointer py-2" dangerouslySetInnerHTML={{ __html: title}}/>
                    <FontAwesomeIcon icon={isActive ? faAngleDown :faAngleRight} className="tracking-widest text-2xl text-[#7FC241] px-2" />
                </div>
                {isActive && <div className="mt-2 text-sm text-left pb-2" dangerouslySetInnerHTML={{ __html: content }}/>}
        
        </div>
      {!index === length-1 ? <hr /> : null}
    </>
    
);
};

export default Accordian;
