import { faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ClipLoader } from 'react-spinners'
import SeoAdditionalFeatures from './SeoAdditionalFeatures'
import { useAddonContext } from '../../context/AddonFeatureContext'

const Pricingcard = ({ score, skill_name, price, featurePrice, setIsOpen, normalFeaturePrice }) => {

    const { showFeatures, setShowFeatures, showLoader } = useAddonContext()

    return (
        <div className={`bg-secondary_background mt-6 p-4 md:p-8 rounded-xl`}>
            {score >= 80 ? <h3 className='text-2xl md:text-[28px] leading-[29px]'>Unlock Instant Certificate To Prove You’re In The Top <span className='font-bold text-span_color'>10% Of {skill_name} Candidates</span></h3> : <h3 className='text-2xl md:text-[28px] leading-[29px]'>Unlock Instant Certificate To View Score & Prove You’re In The Top <span className='font-bold text-span_color'>10% Of {skill_name} Candidates</span></h3>}
            <div className='w-full mt-4 mx-auto' style={{
                boxShadow: '0px 0px 12px 0px #FFCC17'
            }}>
                <img src="https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group%209227.webp?updatedAt=1720514693587" alt="" />
            </div>
            <p className='text-[13px] md:text-xl mt-1 font-normal'><span className='text-[#2BBD13] font-medium'>8/10</span> CV’s With This Certificate Got Hired</p>
            <div className='bg-[#7FC241] mt-4 md:mt-5 w-[85%] cursor-pointer flex justify-center items-center mx-auto text-black py-3 rounded-lg btn--glow' onClick={() => setIsOpen(true)}>
                <p className='font-semibold md:text-[21px]'>{showLoader ? <ClipLoader color='black' size={20} /> : 'Get Instant Certificate'}</p>
                {!showLoader && <FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 arrow-move'/>}
            </div>
            <div className='mt-1.5 flex justify-center items-center'>
                <p className='text-sm md:text-lg font-medium mr-1'>For Just: <span className='text-[#80C242]'>&#8377;{price.price + featurePrice()}</span></p>
                <p className='text-sm md:text-lg text-danger'>&#8377;<span className='line-through'>{price.original_price + 
                normalFeaturePrice()}</span></p>
            </div>
            {showFeatures ? <SeoAdditionalFeatures
                skill_name={skill_name}
            /> : <div className='mt-4' onClick={() => setShowFeatures(true)}>
                <p className='text-sm md:text-xl font-medium text-[#7FC241] cursor-pointer'>Explore Additional Features</p>
                <FontAwesomeIcon icon={faChevronDown} className='text-[#7FC241] md:text-xl down' />
            </div>}
        </div>
    )
}

export default Pricingcard