import React from 'react'
import { expertsData } from '../../utilities/constants'

const CocreatedBy = () => {
    return (
        <div className='flex justify-between items-center text-white mt-2'>
            <div>
                <p className='text-[10px] font-medium'>Co-Created By</p>
                <p className='text-sm font-medium'>Ravi Garikipati</p>
            </div>
            <div className='flex gap-1 items-center mt-1'>
                <img src={expertsData?.[0]?.imgLink} width={40} height={40} alt="" className='w-10 h-10 rounded-full object-cover'/>
                <div>
                    <p className='text-[10px] mb-0.5'>ex-CTO Flipkart</p>
                    <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Flipkart%20-%20png%200.png?updatedAt=1704897687384" width={41} height={13} alt="" className='w-[41px] h-auto' />
                </div>
            </div>
        </div>
    )
}

export default CocreatedBy